
import { defineComponent } from "vue";
import KycConfirmationInfomation from "@/components/page/kyc/kyc-confirmation/Information.vue";

export default defineComponent({
  name: "kyc-confirmation-legal-entity-information",
  components: {
    KycConfirmationInfomation,
  },
  setup() {
    return {};
  },
});
